<template>
  <ui-icon
    class="phi-type-icon"
    :value="type.icon"
    :color="type.color"
    :alt="type.singular"
  ></ui-icon>
</template>

<script>
import { UiIcon } from '@/modules/ui/components';

export default {
  name: 'PhiTypeIcon',
  components: { UiIcon },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      return this.value;
    },
  },
};
</script>