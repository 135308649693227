<template>
    <div class="phi-page-top-panel phi-z" :class="{open, closed:!open}">
        <div class="phi-page-top-panel-toolbar">
            <slot name="toolbar"></slot>
        </div>

        <div class="phi-page-top-panel-contents">
            <slot></slot>
        </div>

        <div class="phi-page-top-panel-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PhiPageTopPanel",
    props: {
        open: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss">
.phi-page-top-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    background-color: #fff;

    transition: transform .3s, opacity .3s;

    transform: translateY(-120%);
    opacity: 0;

    &.open {
        opacity: 1;
        transform: translateY(0);
    }

    &-toolbar > div {
        height: 56px;
        padding-right: 12px;

        display: flex;
        align-items: center;

        button {
            background-color: transparent;
            border: none;
            color: inherit;
            padding: 12px 18px;
            margin: 0;
            cursor: pointer;
            font-size: 1em;
        }

        & > h1 {
            flex: 1;
            padding: 0 12px;
            font-size: 18px;
            color: #444;
        }
    }

    &-footer > div {
        display: flex;

        margin: 0;
        padding: 12px;

        & > * {
            flex: 1;
            margin: .5em 1em;
        }

        button {
            padding: 12px;
            font-size: 1em;
            background: rgba(0,0,0,.05);
            border: 0;
            border-radius: 4px;

            &.cancel {
                background: transparent;
            }
        }
    }

}
</style>