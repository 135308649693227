<template>
    <div class="phi-event-list-item">
        <div class="phi-event-list-item-title">
            <phi-type-icon :value="type"></phi-type-icon>
            <h1>{{ value.title }}</h1>
        </div>
        <p>{{ value.secondary ? value.secondary + (label ? ' &bull; ' : '') : ''}} {{ label }}</p>
    </div>
</template>

<script>
import PhiTypeIcon from '@/components/Phi/Type/Icon.vue';

export default {
    name: "PhiEventListItem",
    components: {PhiTypeIcon},

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    computed: {
        event() {
            return this.value;
        },

        type() {
            return this.value.post.type;
        },

        label() {
			let allDay    = this.event.allDay === true || this.event.allDay === '1' || this.event.allDay === 1;
			let startTime = this.$date(this.event.startDate, "time");
			let endTime   = this.$date(this.event.endDate, "time");

			if (allDay) {
				return '';
			} else {
				return startTime == endTime ? startTime : startTime + ' - ' + endTime;
			}
        }
    }
}
</script>

<style lang="scss" scoped>
.phi-event-list-item {
    padding: 8px;
    

    .phi-event-list-item-title {

        display: flex;
        align-items: center;

        .phi-type-icon {
            padding: 3px 5px;
            margin-right: 4px;

            width: 28px;
            height: 28px;

            float: left;
        }

        h1 {
            font-weight: normal;
            font-size: 1.1em;

            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
        }
    }

    p {
        font-size: .9em;
        opacity: .8;
        padding: 2px 4px;
    }

}
</style>